import { DOWNLOAD_REPORT_SELECTION, NO_BRAND_ID } from "@constants/horizon";
import { HorizonContext } from "@context";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import { useSectors, useYougovKPIs } from "@services/api/horizon";
import { useBrandEquityMetricsExcel } from "@services/api/horizon/BrandEquityMetrics";
import { useCountries } from "@services/api/horizon/Countries";
import { getExcelDateFmt } from "@utils/ExcelUtils";
import BrandSelector from "@views/ViewHorizon/components/Selectors/BrandSelector";
import CountrySelector from "@views/ViewHorizon/components/Selectors/CountrySelector";
import IndustrySelector from "@views/ViewHorizon/components/Selectors/IndustrySelector";
import KpiSelector from "@views/ViewHorizon/components/Selectors/KpiSelector";
import MarketBrandsSelector from "@views/ViewHorizon/components/Selectors/MarketBrandsSelector";
import saveAs from "file-saver";
import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const BrandEquitySelectors = () => {
  const { t } = useTranslation();
  const { currentCtx, saveCurrentCtx } = useContext(HorizonContext);
  const [clicked, setClicked] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [excelOption, setExcelOption] = useState<null | boolean>(null);
  const openDownloadReportMenu = Boolean(anchorEl);

  const { data: sectors } = useSectors({ countryId: currentCtx.countryId });
  const { data: kpis } = useYougovKPIs();
  const { data: countries } = useCountries();

  const countryCodeById = useMemo(() => {
    const countryCodeById: { [key: string]: string } = {};
    (countries ?? []).forEach((country) => {
      countryCodeById[country.id.toString()] = country.code;
    });
    return countryCodeById;
  }, [countries]);

  const sectorNameById = useMemo(() => {
    const sectorsById: { [key: string]: string } = {};
    (sectors ?? []).forEach((sector) => {
      sectorsById[sector.id.toString()] = sector.label;
    });
    return sectorsById;
  }, [sectors]);

  const kpiNameById = useMemo(() => {
    const kpisById: { [key: string]: string } = {};
    (kpis ?? []).forEach((kpi) => {
      kpisById[kpi.id.toString()] = kpi.label;
    });
    return kpisById;
  }, [kpis]);

  const handleComputeTrendChange = (event: ChangeEvent<HTMLInputElement>) => {
    saveCurrentCtx({
      ...currentCtx,
      brandEquityCtx: {
        ...currentCtx.brandEquityCtx,
        withTrend: event.target.checked
      }
    });
  };

  const handleGenerateInsightsChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    saveCurrentCtx({
      ...currentCtx,
      brandEquityCtx: {
        ...currentCtx.brandEquityCtx,
        withPrediction: event.target.checked
      }
    });
  };

  const handleCloseDownloadReportButton = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option: DOWNLOAD_REPORT_SELECTION) => {
    const newOption = option === DOWNLOAD_REPORT_SELECTION.ALL;
    setExcelOption(newOption);
    setAnchorEl(null);
  };

  const {
    data: excelBlob,
    refetch: refetchExcel,
    isFetching: isFetchingExcel
  } = useBrandEquityMetricsExcel({
    kpiId: currentCtx.kpiId,
    sectorId: currentCtx.sectorId,
    trend: currentCtx.brandEquityCtx.withTrend,
    countryId: currentCtx.countryId,
    allSectors: excelOption ?? false
  });

  const excelFileName = useMemo(() => {
    const countryCode =
      countryCodeById[currentCtx.countryId] ?? currentCtx.countryId;
    const sectorName =
      sectorNameById[currentCtx.sectorId] ?? currentCtx.sectorId.toString();

    const kpiName =
      kpiNameById[currentCtx.kpiId] ?? currentCtx.kpiId.toString();
    return `Report_Brand_Equity_${countryCode}_${excelOption ? "All_Sectors" : sectorName}_${kpiName}${currentCtx.brandEquityCtx.withTrend ? "_Trend" : ""}_${getExcelDateFmt()}.xlsx`;
  }, [
    currentCtx.sectorId,
    currentCtx.kpiId,
    currentCtx.brandEquityCtx.withTrend,
    currentCtx.countryId,
    excelOption,
    kpiNameById,
    sectorNameById,
    countryCodeById
  ]);

  useEffect(() => {
    if (excelBlob && clicked && !isFetchingExcel) {
      saveAs(excelBlob, excelFileName);
      setClicked(false);
      setExcelOption(null);
    }
  }, [excelBlob, excelFileName, clicked, isFetchingExcel]);

  const handleDownloadReport = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isFetchingExcel) {
      setAnchorEl(event.currentTarget);
    }
  };

  const isEnabledDownloadForecastButton: boolean = useMemo(() => {
    return currentCtx.kpiId.length > 0 && currentCtx.countryId.length > 0;
  }, [currentCtx.kpiId, currentCtx.countryId]);

  const isEnabledPredictButton: boolean = useMemo(() => {
    return (
      currentCtx.sectorId.length > 0 &&
      currentCtx.kpiId.length > 0 &&
      currentCtx.brandId.length > 0
    );
  }, [currentCtx]);

  const isEnabledTrendButton: boolean = useMemo(() => {
    return currentCtx.kpiId.length > 0;
  }, [currentCtx.kpiId]);

  const isEnabledDownloadSectorOption: boolean = useMemo(
    () => currentCtx.sectorId.length > 0,
    [currentCtx.sectorId]
  );

  useEffect(() => {
    if (isEnabledDownloadForecastButton && excelOption !== null) {
      setClicked(true);
      refetchExcel();
    }
  }, [excelOption, refetchExcel, isEnabledDownloadForecastButton]);

  const customOpts = [
    { id: NO_BRAND_ID, label: t("horizon.brand_equity.industry_avg_selector") }
  ];

  return (
    <Box sx={{ height: "100%" }}>
      <Paper
        sx={{
          height: "100%",
          display: "flex",
          gap: 2,
          flexDirection: "column",
          justifyContent: "space-between",
          padding: 2,
          paddingBottom: 4
        }}
        elevation={0}
      >
        <Box>
          <Typography variant="h5" gutterBottom>
            Horizon
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <CountrySelector />
            <IndustrySelector />
            <KpiSelector />
            <BrandSelector customOpts={customOpts} />
            <MarketBrandsSelector />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              marginTop: 2
            }}
          >
            <FormGroup>
              <FormControlLabel
                sx={{ height: "2rem" }}
                control={
                  <Checkbox
                    checked={currentCtx.brandEquityCtx.withTrend}
                    disabled={!isEnabledTrendButton}
                    onChange={handleComputeTrendChange}
                  />
                }
                label={t("horizon.brand_equity.compute_trend")}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                sx={{ height: "2rem" }}
                control={
                  <Checkbox
                    checked={currentCtx.brandEquityCtx.withPrediction}
                    disabled={!isEnabledPredictButton}
                    onChange={handleGenerateInsightsChange}
                  />
                }
                label={t("horizon.brand_equity.generate_insights")}
              />
            </FormGroup>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Button
            variant="contained"
            disabled={!isEnabledDownloadForecastButton}
            onClick={handleDownloadReport}
            sx={{
              textTransform: "none"
            }}
          >
            {isFetchingExcel && (
              <CircularProgress size="1.5rem" sx={{ color: "white" }} />
            )}
            {!isFetchingExcel && t("horizon.brand_equity.download_forecast")}
          </Button>
          <Menu
            id="brand-equity-download-report-menu"
            anchorEl={anchorEl}
            open={openDownloadReportMenu}
            onClose={handleCloseDownloadReportButton}
            MenuListProps={{
              "aria-labelledby": "basic-button"
            }}
          >
            <MenuItem
              onClick={() =>
                handleMenuItemClick(DOWNLOAD_REPORT_SELECTION.CURRENT_SELECTION)
              }
              disabled={!isEnabledDownloadSectorOption}
            >
              {t("horizon.brand_equity.download.current_selection")}
            </MenuItem>
            {(sectors || []).length > 1 && (
              <MenuItem
                onClick={() =>
                  handleMenuItemClick(DOWNLOAD_REPORT_SELECTION.ALL)
                }
              >
                {t("horizon.brand_equity.download.all_sectors")}
              </MenuItem>
            )}
          </Menu>

          <Box sx={{ fontSize: 14 }}>
            <span>* {t("horizon.brand_equity.helpers.download_report")}</span>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default BrandEquitySelectors;
