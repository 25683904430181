import AppNavBar from "@components/AppNavBar";
import TuneIcon from "@mui/icons-material/Tune";
import { Box, Button, Grid, useMediaQuery } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import { forwardRef, PropsWithChildren, useState } from "react";

export type LayoutDefaultElement = HTMLDivElement;

export type LayoutDefaultProps = {
  title: string;
  tabs: JSX.Element[];
  selectors?: JSX.Element;
  className?: string;
};

export const LayoutDefault = forwardRef<
  LayoutDefaultElement,
  PropsWithChildren<LayoutDefaultProps>
>(({ title, tabs, selectors, children, className }, ref) => {
  const theme = useTheme();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const contentHeight = `calc(${theme.customLayoutHeight.rootHeight} - ${theme.customLayoutHeight.appNavBarHeight})`;

  return (
    <div
      ref={ref}
      className={className}
      style={{
        height: theme.customLayoutHeight.rootHeight
      }}
    >
      <AppNavBar title={title} tabs={tabs} />
      <Box
        id="main-content-container"
        sx={{
          height: contentHeight,
          overflow: "auto"
        }}
      >
        {/* DRAWER FOR MOBILE - SELECTORS */}
        {isTablet ? (
          <>
            {selectors ? (
              <>
                <Box
                  sx={{
                    position: "sticky",
                    top: 0,
                    background: "#fefefe",
                    opacity: 1,
                    zIndex: 10,
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    paddingX: "8px"
                  }}
                >
                  <Button
                    startIcon={<TuneIcon fontSize="small" />}
                    onClick={toggleDrawer}
                  >
                    Selectors
                  </Button>
                </Box>
                <Drawer
                  anchor="left"
                  open={isDrawerOpen}
                  onClose={() => setIsDrawerOpen(false)}
                  ModalProps={{
                    keepMounted: true
                  }}
                >
                  <Box
                    sx={{
                      width: "90vw",
                      height: theme.customLayoutHeight.rootHeight
                    }}
                  >
                    {selectors}
                  </Box>
                </Drawer>
              </>
            ) : null}
            {children}
          </>
        ) : null}
        {!isTablet && (
          <Box sx={{ height: "inherit" }}>
            <Grid
              container
              sx={{
                height: "inherit"
              }}
            >
              {selectors ? (
                <Grid
                  sx={{
                    height: "inherit",
                    overflowY: "auto"
                  }}
                  item
                  xs={3}
                >
                  {selectors}
                </Grid>
              ) : null}
              <Grid
                sx={{
                  maxHeight: contentHeight,
                  overflowY: "auto"
                }}
                item
                xs={selectors ? 9 : 12}
              >
                {children}
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </div>
  );
});

export default LayoutDefault;
