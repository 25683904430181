import { HorizonContext } from "@context";
import { BarChartConnectedBarsType } from "@dive.tv/dss-ui-graphs/BarChart";
import BarChartConnectedBars from "@dive.tv/dss-ui-graphs/BarChart/BarChartConnectedBars/BarChartConnectedBars";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SwipeIcon from "@mui/icons-material/Swipe";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useFunnelStateMetrics } from "@services/api/horizon/FunnelStateMetrics";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { validateData } from "../../utils.ts/validateData";

const Charts = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const { currentCtx } = useContext(HorizonContext);

  const { data, isLoading } = useFunnelStateMetrics({
    sectorId: currentCtx.sectorId,
    brandId: currentCtx.brandId
  });

  const current_date: Date = useMemo(() => new Date(), []);

  const [dataLastYear, ,] = data ?? [];

  const isDataValid = !isLoading && data && validateData(dataLastYear);

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: "#F2F4F7",
        height: "100%",
        padding: 1
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          paddingLeft: "1rem",
          paddingRight: "1rem"
        }}
      >
        <Typography variant="h5" component="h1" gutterBottom>
          {t("horizon.tabs.funnel_state")}
        </Typography>
        {isLoading && data === undefined && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%"
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!isLoading && data && data.length > 0 ? (
          <Grid
            container
            rowSpacing={1}
            sx={{
              height: "100%"
            }}
          >
            {isDataValid ? (
              <>
                {data.map((dataPeriod) => {
                  const cardTitle =
                    dataPeriod.year === current_date.getFullYear() - 1
                      ? `${t("horizon.funnel_state.past_data")} (${current_date.getFullYear() - 1})`
                      : dataPeriod.year === current_date.getFullYear()
                        ? `${t("horizon.funnel_state.current_data")} (${current_date.getFullYear()})`
                        : dataPeriod.year === current_date.getFullYear() + 1
                          ? `${t("horizon.funnel_state.predicted_data")} (${current_date.getFullYear() + 1})`
                          : "";
                  return (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        height: isTablet ? "480px" : "420px",
                        paddingBottom: 4
                      }}
                    >
                      <Card
                        sx={{
                          padding: 1,
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          position: "relative",
                          paddingBottom: 4
                        }}
                      >
                        <CardHeader
                          sx={{
                            padding: 0,
                            textAlign: isTablet ? "center" : "unset"
                          }}
                          title={
                            <Typography variant="h6">{cardTitle}</Typography>
                          }
                        />
                        <CardContent
                          sx={{
                            height: "100%",
                            padding: "0 !important",
                            margin: 0,
                            flexGrow: 1,
                            position: "relative",
                            overflowX: isTablet ? "scroll" : "none", // Estilos para "quitar" la barra de scroll
                            scrollbarWidth: "none", // Estilos para "quitar" la barra de scroll - Firefox
                            "&::-webkit-scrollbar": {
                              display: "none" // Estilos para "quitar" la barra de scroll - Chrome, Safari y Edge
                            }
                          }}
                        >
                          <Box
                            sx={{
                              position: "absolute",
                              height: "100%",
                              width: isMobile ? "1276px" : "100%"
                            }}
                          >
                            <BarChartConnectedBars
                              data={dataPeriod as BarChartConnectedBarsType}
                            />
                          </Box>
                        </CardContent>
                        {isMobile ? (
                          <SwipeIcon
                            sx={{
                              position: "absolute",
                              bottom: 10,
                              opacity: 0.4,
                              left: "50%",
                              transform: "translate(-50%, 0)",
                              color: theme.palette.primary.main
                            }}
                            fontSize="small"
                          />
                        ) : null}
                      </Card>
                    </Grid>
                  );
                })}
              </>
            ) : (
              <Grid item xs={12}>
                <Card
                  sx={{
                    padding: 1,
                    height: "100%",
                    minHeight: isTablet ? "620px" : "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center"
                  }}
                >
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: isTablet ? "column" : "row",
                      gap: 2,
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <SearchOffIcon
                      fontSize="large"
                      sx={{ color: theme.palette.primary.main }}
                    />
                    <Typography variant="h4">
                      {t("common.no_data_available")}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        ) : null}
      </Box>
    </Paper>
  );
};

export default Charts;
